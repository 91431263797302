<template>
  <div class="">
    <el-card class = "cut-in-card flexCenter">
      <div class = "flexCenter" style="margin-top:-70px">
        <img src="https://cdn.dkycn.cn/images/melyshop/menuIcon/xiaoshouqudao/shipinhaozhibo.png" style="width:30px;height:30px"  alt="">
        <div class ="grayFont" style="margin-left:10px">视频号直播</div>
        <i class = "el-icon-close grayFont" style="margin:0px 15px;font-size:30px"></i>
        <img src="https://cdn.dkycn.cn/melyshop/image/20210730-b62b0dbb-c383-4d16-9e9f-af292aa8bc71.png" style="width:30px;"  alt="">
        <div class ="grayFont" style="margin-left:10px">企店</div>
      </div>
      <div class = "flexCenter grayFont" style="font-size:14px;margin:40px auto 5px auto">
        商城还未接入{{font}}，请先完成接入
      </div>
      <div class = "flexCenter">
        <el-button type="text" @click="gocutin">去接入</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import {videolivevideoliveapplyinfo} from "@/api/sv1.0.0"
export default {
  props:{
    font:{
      type:String,
      default:'自定义版交易组件'
    }
  },
  data () {
    return {

    }
  },
  methods: {
    async getvideolivevideoliveapplyinfo(){
      this.pageloading = true
      let result = await videolivevideoliveapplyinfo()
      if(result.IsSuccess){
        // console.log(result.Result.State)
        this.$emit('getIsOpen',result.Result.State)

        // this.FailReason = result.Result.FailReason
        // this.WxAppNickName = result.Result.WxAppNickName
      }
      this.pageloading = false
    },
    
    gocutin(){
      this.$router.push({
        path:'/setting/liveVideo/liveCutIn'
      })
      this.$router.push({
        path:'/setting/liveVideo/liveCutIn'
      })
      // console.log('新标签页打开视频号直播开通流程页')
    },  
  }
}
</script>

<style lang = "less">
.flexRow{
  display: flex;
}
  .cut-in-card{
    /* width:99%; */
    flex:1;
    position: absolute;
    top: 10px;
    bottom: 10px;
    left:10px;
    right:10px;
  }
  .flexCenter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .grayFont{
    color: #909399;
    font-size:16px
  }
</style>