<template>
  <div v-loading = "pageloading">
    <cut-in v-if="Access<4" :font="'视频号'"></cut-in>
    <div v-else>
      <el-card>
        <div class = "titleFont">添加商品适用的类目并提交微信审核，审核通过的类目可在添加商品时选择。</div>
        <div class = "titleFont">1.部分类目需要上传行业资质或商品资质；类目行业资质详情：
          <el-button type="text"><a href="https://jusnn6k8al.feishu.cn/docs/doccnRuLnpwJ1F4CiS9QK2g1vHc" target="_blank">点击查看</a></el-button>
          <span class = "titleFont"> ；类目商品资质详情：</span>
          <el-button type="text"><a href="https://jusnn6k8al.feishu.cn/docs/doccn92eLJiHpRsoplvdm0OaUtf" target="_blank">点击查看</a></el-button></div>
        <div class = "titleFont">2.已经在微信侧审核通过的类目不支持删除</div>
      </el-card>
      <el-card style="margin-top:10px">
        <div class ="flexRow" style="justify-content: space-between;">
          <div class ="flexRow" style="flex-wrap:wrap">
            <div class ="flexRow">
              <div style="font-size:14px;line-height:37px" class = "boldFont">审核状态：</div>
              <el-select v-model="state" style="wdith:250px">
                <el-option v-for="(v,i) in statelist" :key="i" :value="v.value" :label="v.label"></el-option>
              </el-select>
            </div>
            <div class ="flexRow">
              <el-button type = "primary" style="margin:0px 10px" @click="query">查询</el-button>
              <buttonPermissions :datas="'liveVideoPushclassity'" style="margin-right:5px">
                <el-button type="primary" @click="pushclassity">添加类目</el-button>
              </buttonPermissions>
            </div>
            <buttonPermissions :datas="'liveVideoManagementlicenseopen'" style="margin-right:5px">
              <el-button type="text" style="margin-left:15px" @click="managementlicenseopen">管理营业执照>>></el-button>
            </buttonPermissions>
          </div>
          <div>
            <el-link href = "https://jusnn6k8al.feishu.cn/docs/doccnFFz9aMGqOftm2pqnL3eBYb" type = "info" target="_blank" style="margin-top:10px">
              <i class = "el-icon-document" style="margin-right:5px"></i>商品类目管理说明
            </el-link>
          </div>
        </div>
        <div style="margin-top:10px">
          <el-table :data="tablelist" v-loading ="tableloading">
            <el-table-column prop="" label="类目" width="400">
              <template slot-scope="scope">
                <div class = "fontHidden3" style="width:300px">
                  {{scope.row.FirstCatName}} > {{scope.row.SecondCatName}} > {{scope.row.ThirdCatName}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="StateValue" label="状态">
              <template slot-scope="scope">
                {{scope.row.StateValue}}
              </template>
            </el-table-column>
            <el-table-column prop="" label="驳回原因" width="600">
              <template slot-scope="scope">
                <div v-if="!scope.row.FailReason">— —</div>
                <el-popover v-else placement="top" width="600" trigger="hover" :content="scope.row.FailReason">
                  <div slot="reference" class = "fontHidden3" style="width:500px">
                    {{scope.row.FailReason}}
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" width="300px">
              <template slot-scope="scope">
                <div class = "flexRow">
                  <!-- 审核中  审核通过 -->
                    <div v-if="scope.row.State == 1||scope.row.State == 2">
                      <buttonPermissions :datas="'liveVideoQualificationwindow'" class = "operation" style="margin-right:5px;">
                        <el-button type="text" @click="qualificationwindow(scope.row)">资质材料</el-button>
                      </buttonPermissions>
                    </div>
                  <!-- 审核通过 -->
                    <div v-if="scope.row.State == 2">
                      <buttonPermissions :datas="'liveVideoChangeshop'" class = "operation" style="margin-right:5px;">
                          <el-button type="text" @click="changeshop(scope.row)">修改商品资质</el-button>
                      </buttonPermissions>
                    </div>
                  <!-- 审核驳回 -->
                    <div v-if="scope.row.State == 3">
                      <buttonPermissions :datas="'liveVideoAgainAudit'" class = "operation" style="margin-right:5px;">
                        <el-button type="text" @click="againAudit(scope.row)">重新提审</el-button>
                      </buttonPermissions>
                      <buttonPermissions :datas="'liveVideoDeteleclass'" class = "operation" style="margin-right:5px;">
                        <el-button type="text" style="color:rgb(245,108,108);" @click="deteleclass(scope.row)" >删除</el-button>
                      </buttonPermissions>
                    </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align:right;width:100%;margin-top:10px" v-if="total">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="sizepage"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </el-card>
      <!-- 资质材料 -->
      <el-dialog title="资质材料" :visible.sync="qualificationshow">
        <div style="max-height:600px;overflow:hidden;overflow-y:auto">
          <div class = "flexRow" v-if="materialImgs.business">
            <div style="color:#909399;width:100px">营业执照：</div>
            <div style="flex:1">
              <img :src="imgUrl+materialImgs.business" style="max-width:100%" alt="">
            </div>
          </div>
          <div class = "flexRow" v-if="materialImgs.category&&materialImgs.category.length">
            <div style="color:#909399;width:100px">类目资质：</div>
            <div style="flex:1">
              <img v-for="(v,i) in materialImgs.category" :key="i" :src="imgUrl+v" style="max-width:100%;margin:0px 10px 10px 0px" alt="">
            </div>
          </div>
          <div class = "flexRow" v-if="materialImgs.shop&&materialImgs.shop.length">
            <div style="color:#909399;width:100px">商品资质：</div>
            <div style="flex:1">
              <img v-for="(v,i) in materialImgs.shop" :key="i" :src="imgUrl+v" style="max-width:100%;margin:0px 10px 10px 0px" alt="">
            </div>
          </div>
        </div>
        <div class = "diaLog-buttonBox">
          <el-button @click="qualificationshow = false">关闭</el-button>
        </div>
      </el-dialog>
      <!-- 若商户营业执照为空，优先弹出管理营业执照弹框 -->
      <!-- 添加营业执照 -->
      <el-dialog title="管理营业执照" :visible.sync="businessshow" @closed = "closedChooseImg" v-loading = "businessloading">
        <el-form :model="businessruleForm" :rules="businessrules" ref="ruleForm">
          <el-form-item label="营业执照：" prop="img" label-width="120px">
            <div style="color:#909399">根据微信官方要求，首次添加类目请同时提交营业执照或组织机构代码证</div>
            <div calss = "flexRow" style="overflow:hidden">
              <div style="float:left">
                <el-upload :disabled ="disabled" :action="imgBase" list-type="picture-card" accept="image/*" :show-file-list ='false'
                :on-success="imgOnSuccess" :before-upload ="beforeUpload" :on-error = "onError">
                  <img :src="imgUrl+businessruleForm.img" v-if="businessruleForm.img" style="width:100%;height:100%"  alt="" />
                  <i slot="default" class="el-icon-plus" v-else></i>
                </el-upload>
              </div>
              <div style="float:left;margin:120px 0px 0px 10px" v-if="businessruleForm.img">
                <el-button type="text" @click="lookbigPicture(businessruleForm.img)">查看大图</el-button>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class = "diaLog-buttonBox">
          <el-button @click="closedChooseImg">取消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        </div>
      </el-dialog>
      <!-- 查看大图 -->
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="imgUrl+bigpicture" alt="">
      </el-dialog>
      <!-- 添加类目  重新提审 -->
      <el-dialog :title="pushtitle" :visible.sync="pushshow" @closed = "pushclose" width="700px" v-loading = "pushloading">
        <el-form :model="pushruleForm" :rules="pushrules" ref="pushruleForm">
          <el-form-item prop="classity" label="选择类目：" label-width="100px">
            <el-cascader v-if="pushshow" :disabled ="pushtitle=='重新提审'" style="width:400px" filterable v-model="pushruleForm.classity"
             placeholder = "请选择类目" separator=" > " :options="classityList" @change="classityChange" ref="pushcascader"></el-cascader>
          </el-form-item>
          <div v-if="pushruleForm.classity&&pushruleForm.classity.length" v-loading = "aptitudeLoading">
            <div v-if="pushruleForm.classtype!=0">
              <el-form-item :prop="pushruleForm.classtype==1?'classqualification':''"
               :rules="pushruleForm.classtype==1?pushrules.classqualification:[{required: false}]"
               label="类目资质：" label-width="100px">
                <div  style="color:#909399;line-height:16px;font-size:12px;margin:10px 0px">
                  {{pushruleForm.classqualificationText}}
                </div>
                <div class="flexRow" style="width:100%;flex-wrap:wrap;">
                    <div class = "touchImg" style="margin:0px 20px 10px 0px" v-if="pushruleForm.classqualification&&pushruleForm.classqualification.length"
                     v-for="(v,i) in pushruleForm.classqualification" :key="i">
                      <img :src="imgUrl+v" style="width:148px;height:148px;"  alt="" />
                      <div class = "markBox">
                        <div class = "markBox2">
                          <i class = "el-icon-zoom-in" style="font-size:20px;color:white; cursor:pointer" @click="lookbigPicture(v)"></i>
                          <i class = "el-icon-delete" style="font-size:20px;color:white; cursor:pointer" @click="deteleImg2(i)"></i>
                        </div>
                      </div>
                    </div>
                  <el-upload :action="imgBase" list-type="picture-card" accept="image/*"
                  :show-file-list ='false' :on-success="imgOnSuccess2" :before-upload ="beforeUpload2">
                    <i slot="default" class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </el-form-item>
            </div>
            <div v-if="pushruleForm.shoptype!=0">
              <el-form-item :prop="pushruleForm.shoptype==1?'shopqualification':''" 
              :rules="pushruleForm.shoptype==1?pushrules.shopqualification:[{required: false}]"
               label="商品资质：" label-width="100px">
                <div  style="color:#909399;line-height:16px;font-size:12px;margin:10px 0px">
                  {{pushruleForm.shopqualificationText}}
                </div>
                <div class="flexRow" style="width:100%;flex-wrap:wrap;">
                  <div class = "touchImg" style="margin:0px 20px 10px 0px" v-if="pushruleForm.shopqualification&&pushruleForm.shopqualification.length"
                   v-for="(v,i) in pushruleForm.shopqualification" :key="i">
                   <!-- {{v}} -->
                      <img :src="imgUrl+v" style="width:148px;height:148px"  alt="" />
                      <div class = "markBox">
                        <div class = "markBox2">
                          <i class = "el-icon-zoom-in" style="font-size:20px;color:white; cursor:pointer" @click="lookbigPicture(v)"></i>
                          <i class = "el-icon-delete" style="font-size:20px;color:white; cursor:pointer" @click="deteleImg3(i)"></i>
                        </div>
                      </div>
                    </div>
                  <el-upload :action="imgBase" list-type="picture-card" accept="image/*"
                  :show-file-list ='false' :on-success="imgOnSuccess3" :before-upload ="beforeUpload3">
                    <i slot="default" class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class = "diaLog-buttonBox">
          <el-button @click="pushclose">取消</el-button>
          <el-button type="primary" @click="submitForm3('pushruleForm')">提交审核</el-button>
        </div>
      </el-dialog>

      <!-- 修改商品资质 -->
      <el-dialog  title="修改商品资质" :visible.sync="changeshopshow" width="700px"  @closed = "shopclose" v-loading = 'changeshoploading'>
        <div>
          <div  style="color:#909399;line-height:16px;font-size:12px;margin:10px 0px">
            商品资质修改完成以后，将会在你提审该类目下的商品时，一周提交给微信官方审核
          </div>
          <el-form :model="shopruleForm" :rules="shoprules" ref="shopruleForm">
            <el-form-item label="类目：" label-width="100px">
              <el-cascader filterable clearable v-model="shopruleForm.classity" placeholder = "请选择类目" separator=" > "
            :disabled ="true" :options="classityList"></el-cascader>
            </el-form-item>
            <!-- <div v-if="shopruleForm.shoptype!=0"> -->
              <el-form-item :prop="shopruleForm.shoptype==1?'shop':''"
              :rules="shopruleForm.shoptype==1?shoprules.shop:[{required: false}]"
               label="商品资质：" label-width="100px">
                <div  style="color:#909399;line-height:16px;font-size:12px;margin:10px 0px">
                  {{shopruleForm.shopText}}
                </div>
                <div class="flexRow" style="width:100%;flex-wrap:wrap;">
                  <div class = "touchImg" style="margin:0px 20px 10px 0px" v-if="shopruleForm.shop&&shopruleForm.shop.length"
                   v-for="(v,i) in shopruleForm.shop" :key="i">
                      <img :src="imgUrl+v" style="width:148px;height:148px"  alt="" />
                      <div class = "markBox">
                        <div class = "markBox2">
                          <i class = "el-icon-zoom-in" style="font-size:20px;color:white; cursor:pointer" @click="lookbigPicture(v)"></i>
                          <i class = "el-icon-delete" style="font-size:20px;color:white; cursor:pointer" @click="deteleImg4(i)"></i>
                        </div>
                      </div>
                    </div>
                  <el-upload :action="imgBase" list-type="picture-card" accept="image/*"
                  :show-file-list ='false' :on-success="imgOnSuccess4" :before-upload ="beforeUpload4">
                    <i slot="default" class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </el-form-item>
            <!-- </div> -->
            
          </el-form>
        </div>
        <div class = "diaLog-buttonBox">
          <el-button @click="shopclose">取消</el-button>
          <el-button type="primary" @click="submitForm4('shopruleForm')">保存</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
import config from "@/config"
import {
  videolivebusinesslicense,
  videolivevideoliveapplyinfo,
  videolivebusinesslicenseedit,
  videolivesystemvideolivecategorypagelist,
  videolivevideolivewxappcatlist,
  videoliveaddvideolivecategory,
  videolivevideolivecategoryresubmit,
  videolivesystemvideolivecategorydelete,
  videolivevideolivecategoryinfo,
  videolivevideolivecategoryproductqualificationedit
}from "@/api/sv1.0.0"
import cutIn from "./components/cutIn"
export default {
  components: {
    cutIn,
    buttonPermissions
  },
  data () {
    var validatePass = (rule, value, callback) => {
      if(!value){
        this.$message.error('请上传营业执照或组织机构代码证')
        callback(new Error('请上传营业执照或组织机构代码证'))
      }else{
        callback()
      }
    }
    var verifyclassity = (rule, value, callback) =>{
      if(value&&value.length){
        callback()
      }else{
        callback(new Error('请选择类目'))
      }
    }
    var verifyclassqualification = (rule, value, callback) =>{
      if(value){
        callback()
      }else{
        callback(new Error('请上传类目资质'))
      }
    }
    var verifyshopqualification = (rule, value, callback) =>{
      if(value&&value.length){
        callback()
      }else{
        callback(new Error('请选择商品资质'))
      }
    }
    return {
        Access:0,
        pageloading:false,
        isbusiness:true,
        imgBase:config.UPLOAD_IMG,
        imgUrl:config.IMG_BASE,
        state:null,
        statelist:[
          {value:null,label:'全部'},
          {value:1,label:'审核中'},
          {value:2,label:'审核通过'},
          {value:3,label:'审核驳回'},
        ],
        tablelist:[],
        tableloading:false,
        currentPage:1,
        sizepage:20,
        total:null,
        qualificationshow:false,
        businessshow:false,
        businessloading:false,
        isfirst:true,//查询营业执照时判断是否为第一次上传,查询为空表示第一次上传，再上传成功之后自动打开添加类目
        businessruleForm:{
          img:''
        },
        businessrules:{
          img:[
            {required: true, validator: validatePass, trigger: 'blur' },
          ]
        },
        dialogVisible:false,
        disabled: false,

        bigpicture:'',//查看大图img

        pushtitle:'添加类目',
        pushshow:false,
        pushloading:false,
        pushruleForm:{
          classity:[],
          classtype:-1,
          classqualification:[],
          shoptype:-1,
          shopqualification:[]
        },
        pushrules:{
          classity:[
            {required: true,validator: verifyclassity, trigger: 'blur' },
          ],
          classqualification:[
            {required: true,validator: verifyclassqualification, trigger: 'blur' },
          ],
          shopqualification:[
            {required: true,validator: verifyshopqualification, trigger: 'blur' },
          ]
        },
        classityList:[],
        aptitudeLoading:false,
        changeshopshow:false,
        changeshoploading:false,
        shopruleForm:{
          classity:[],
          shop:[],
          Id:'',
          shopText:'',
          shoptype:0,
        },
        shoprules:{
          shop:[
            {required: true, message:'请上传商品资质', trigger: 'blur' },
          ]
        },
        materialImgs:{
          business:'',
          category:'',
          shop:''
        }
    }
  },
  created () {
    this.getvideolivevideoliveapplyinfo()
  },
  methods: {
    async getvideolivevideoliveapplyinfo(){
      this.pageloading = true
      let result = await videolivevideoliveapplyinfo()
      if(result.IsSuccess){
        // console.log(result.Result)
        this.Access = result.Result.State
        // console.log(this.Access)
        if(this.Access>=4){
          this.managementlicense()
          //表格数据
          this.gettablelist()
          //查询所有类目
          this.getvideolivevideolivewxappcatlist()
        }
      }
      this.pageloading = false
    },


    
    //修改商品资质
    changeshop(e){
      // console.log(e)
      this.shopruleForm.classity = [e.FirstCatId,e.SecondCatId,e.ThirdCatId]
      this.shopruleForm.shop = JSON.parse(JSON.stringify(e.ProductQualificationList))
      this.shopruleForm.shoptype = e.ProductQualificationType
      this.shopruleForm.shopText = e.ProductQualification
      this.shopruleForm.Id = e.Id
      this.changeshopshow = true
    },
    //重新提审
    againAudit(e){
      this.pushtitle = '重新提审'
      this.pushshow = true
      // console.log(e)
      this.pushruleForm = {
        Id:e.Id,
        classity:[e.FirstCatId,e.SecondCatId,e.ThirdCatId],
        classtype:e.QualificationType,
        classqualification:e.CategoryQualificationList,
        classqualificationText:e.Qualification,
        shoptype:e.ProductQualificationType,
        shopqualification:e.ProductQualificationList,
        shopqualificationText:e.ProductQualification
      }
    },
    async tovideolivevideolivecategoryresubmit(){
      this.pushloading = true
      try{
        console.log(this.pushruleForm)
        let data = {
          Id:this.pushruleForm.Id,
          CategoryQualificationList:this.pushruleForm.classqualification,
          ProductQualificationList:this.pushruleForm.shopqualification
        }
        // console.log('videolivevideolivecategoryresubmit',23)
        let result = await videolivevideolivecategoryresubmit(data)

        if(result.IsSuccess){
          this.$message.success('操作成功')
        }
      }finally{
        this.pushloading = false
        this.pushshow = false
        this.gettablelist()
      }
    },
    //选择类目
    classityChange(e){
      // console.log(e)
      let a = this.classityList.find((v)=>{
        return v.value == e[0]
      })
      let b = a.children.find((v)=>{
        return v.value == e[1]
      })
      let c = b.children.find((v)=>{
        return v.value == e[2]
      })
      this.pushruleForm.classity = e
      this.pushruleForm.classtype = c.qualification_type
      this.pushruleForm.classqualificationText = c.qualification
      this.pushruleForm.shoptype = c.product_qualification_type
      this.pushruleForm.shopqualificationText = c.product_qualification
      // console.log(this.pushruleForm)
      // this.aptitudeLoading = true
    },
    //修改商品资质
    submitForm4(formName){
      this.$refs[formName].validate((valid) => {
          if (valid) {
            // alert('submit!');
            this.tovideolivevideolivecategoryproductqualificationedit()
          } else {
            // console.log('error submit!!');
            this.$message.error('请完善资质材料')
            return false;
          }
        });
    },
    async tovideolivevideolivecategoryproductqualificationedit(){
      this.changeshoploading = true
      try{
        let data = {
          Id:this.shopruleForm.Id,
          ProductQualificationList:this.shopruleForm.shop
        }
        let result = await videolivevideolivecategoryproductqualificationedit(data)
        if(result.IsSuccess){
          // console.log(result)
          this.$message.success('操作成功')
        }
      }finally{
        this.changeshopshow = false
        this.changeshoploading = false
        this.gettablelist()
      }
    },
    //添加类目提交审核
    submitForm3(formName){
      this.$refs[formName].validate((valid) => {
          if (valid) {
            // alert('submit!');
            if(this.pushtitle == '重新提审'){
              this.tovideolivevideolivecategoryresubmit()
            }else if(this.pushtitle == '添加类目'){
              this.tovideoliveaddvideolivecategory()
            }
          } else {
            // console.log('error submit!!');
            this.$message.error('请完善资质材料')
            return false;
          }
        });
    },
    async tovideoliveaddvideolivecategory(){
      this.pushloading = true
      try{
        // console.log(this.pushruleForm)
        let data = {
          third_cat_id:this.pushruleForm.classity[2],
          CategoryQualificationList:this.pushruleForm.classqualification,
          ProductQualificationList:this.pushruleForm.shopqualification,
        }
        let result = await videoliveaddvideolivecategory(data)
        if(result.IsSuccess){
          // console.log(result)
          this.$message.success('添加成功')
        }
      }finally{
        this.pushloading = false
        this.pushshow = false
        this.gettablelist()
      }
    },
    //上传营业执照 保存
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // alert('submit!');
            this.getvideolivebusinesslicenseedit()
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
    },
    //修改营业执照
    async getvideolivebusinesslicenseedit(){
      this.businessloading = true
      try{
        let data = {
          BusinessLicense:this.businessruleForm.img
        }
        let result = await videolivebusinesslicenseedit(data)
        if(result.IsSuccess){
          this.$message.success('保存成功')
          if(this.isfirst){
            this.pushshow = true
          }
        }
      }finally{
          //表格数据
        this.gettablelist()
        this.businessloading = false
        this.closedChooseImg()
      }
    },
    shopclose(){
      this.shopruleForm.shop = []
      this.$refs.shopruleForm.resetFields();
      this.changeshopshow = false
    },
    pushclose(){
      this.pushshow = false
      this.pushruleForm = {
          classity:[],
          classtype:-1,
          classqualification:[],
          shoptype:-1,
          shopqualification:[]
        },
      this.pushruleForm.classity = []
      this.$refs.pushruleForm.resetFields();
    },
    closedChooseImg(){
      this.businessshow = false
      this.businessruleForm.img = ''
      this.$refs.ruleForm.resetFields();
    },
    deteleImg4(e){
      this.shopruleForm.shop.splice(e,1)
    },
    deteleImg3(e){
      this.pushruleForm.shopqualification.splice(e,1)
    },
    deteleImg2(e){
      this.pushruleForm.classqualification.splice(e,1)
    },
    //查看大图
    lookbigPicture(img){
      this.dialogVisible = true
      this.bigpicture = img
    },
    beforeUpload4(file){
      const size = file.size / 1024 / 1024 < 2;
      const testtype = ['image/bmp','image/png','image/tif','image/gif','image/jpeg','image/jpg','image/webp']
      if(testtype.indexOf(file.type) == -1){
        this.$message.error('请选择图片上传');
        return false
      }else if (!size) {
        this.$message.error('图片大小请控制在2M以内');
        return false
      }
    },
    imgOnSuccess4(file){
      this.shopruleForm.shop = this.shopruleForm.shop.concat(file)
    },
    beforeUpload3(file){
      const size = file.size / 1024 / 1024 < 2;
      const testtype = ['image/bmp','image/png','image/tif','image/gif','image/jpeg','image/jpg','image/webp']
      if(testtype.indexOf(file.type) == -1){
        this.$message.error('请选择图片上传');
        return false
      }else if (!size) {
        this.$message.error('图片大小请控制在2M以内');
        return false
      }
    },
    imgOnSuccess3(file){
      // console.log(file)
      this.pushruleForm.shopqualification = this.pushruleForm.shopqualification.concat(file)
    },
    beforeUpload2(file){
      const size = file.size / 1024 / 1024 < 2;
      const testtype = ['image/bmp','image/png','image/tif','image/gif','image/jpeg','image/jpg','image/webp']
      if(testtype.indexOf(file.type) == -1){
        this.$message.error('请选择图片上传');
        return false
      }else if (!size) {
        this.$message.error('图片大小请控制在2M以内');
        return false
      }
    },
    imgOnSuccess2(file){
      this.pushruleForm.classqualification = this.pushruleForm.classqualification.concat(file)
    },
    //上传时禁止再次点击选择图片  上传完成或失败才能再选
    beforeUpload(file){
      const size = file.size / 1024 / 1024 < 2;
      const testtype = ['image/bmp','image/png','image/tif','image/gif','image/jpeg','image/jpg','image/webp']
      if(testtype.indexOf(file.type) == -1){
        this.$message.error('请选择图片上传');
        return false
      }else if (!size) {
        this.$message.error('图片大小请控制在2M以内');
        return false
      }else{
        this.disabled = true
      }
    },
    onError(){
      this.disabled = false
    },
    imgOnSuccess(file){
      this.businessruleForm.img = file[0]
      this.disabled = false
    },
    managementlicenseopen(){
      this.managementlicense(2)
    },
    //查询营业执照
    async managementlicense(e){
      //1添加类目 2管理营业执照
      let num = 0
      if(e){
        num = await e
      }
      const loading = this.$loading({
          lock: true,
          background: 'rgba(39, 37, 37, 0.8);'
        });
      try{
        let result = await videolivebusinesslicense()
        if(result.IsSuccess){
          this.businessruleForm.img = result.Result.BusinessLicense
          this.isfirst = !this.businessruleForm.img?true:false//查询到为空则表示本次为第一次上传
          // this.$forceUpdate()
          if(num==2){
            this.businessshow = true
          }else if(num==1){
              if(!this.businessruleForm.img){
                this.businessshow = true
              }else{
                this.pushtitle = '添加类目'
                this.pushshow = true
              }
          }
        }
      }finally{
        loading.close()
        // this.businessshow = true
      }
    },
    //获取类目
    async getvideolivevideolivewxappcatlist(){
      let result = await videolivevideolivewxappcatlist()
      if(result.IsSuccess){
        // console.log(result.Result.ThirdCatList)
        this.classityList =  this.assemble(this.filterarr(result.Result.ThirdCatList,1),this.filterarr(result.Result.ThirdCatList,2),this.filterarr(result.Result.ThirdCatList,3))
        // console.log(this.categorylist)
      }
    },
    //筛选类目
    filterarr(arr,num){
      let key = ''
      if(num == 1){
        key = 'first_cat_id'
      }else if(num==2){
        key = 'second_cat_id'
      }else if(num==3){
        key = 'third_cat_id'
      }
      let newarr = []
      arr.forEach((v)=>{
        if(v.first_cat_name&&v.second_cat_name){
          let a = newarr.find((x)=>{
            return x[key] == v[key]
          })
          if(!a){
            newarr.push(v)
          }
        }
      })
      return newarr
    },
    assemble(arr1,arr2,arr3){
      // console.log(arr2)
      let newarr = arr1.map((v)=>{
        let children2 = []
        arr2.map((x)=>{
          let children3 = []
          arr3.map((m)=>{
            // return m.second_cat_id == x.second_cat_id
            if(m.second_cat_id == x.second_cat_id){

              let data2 = {
                value:m.third_cat_id,
                label:m.third_cat_name,
                product_qualification:m.product_qualification,
                product_qualification_type:m.product_qualification_type,
                qualification:m.qualification,
                qualification_type:m.qualification_type
              }
              children3.push(data2)
            }
          })
          // return x.first_cat_id == v.first_cat_id
          if(x.first_cat_id == v.first_cat_id){
            // console.log(x,1)
            let data1 =  {
              value:x.second_cat_id,
              label:x.second_cat_name,
              children:children3
            }
            children2.push(data1)
            // return children2
          }
        })
        return {
          value:v.first_cat_id,
          label:v.first_cat_name,
          children:children2
        }
      })
      return newarr
    },


    //添加类目
    pushclassity(){
      this.managementlicense(1)
      // console.log(this.businessruleForm.img)
    },
    //删除类目
    deteleclass(e){
      this.$confirm('未审核通过的类目，可以删除，后续可以重新添加并提交微信官方审核，是否确认继续删除？','提示',{
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type:'warning'
      }).then(()=>{
        //调用删除接口
        this.tovideolivesystemvideolivecategorydelete(e)
      }).catch(()=>{})
    },
    async tovideolivesystemvideolivecategorydelete(e){
      this.tableloading = true
      let id = await e.Id
      try{
        let data = {
          Id:id
        }
        let result = await videolivesystemvideolivecategorydelete(data)
        if(result.IsSuccess){
          // console.log(result)
          this.$message.success('删除成功')
        }
      }finally{
        this.tableloading = false
        this.gettablelist()
      }
    },
    //资质材料
    qualificationwindow(e){
      // console.log(e)
      // this.materialImgs = e
      this.getvideolivevideolivecategoryinfo(e)
    },
    async getvideolivevideolivecategoryinfo(e){
      this.tableloading = true
      try{
        let id = await e.Id
        let data = {
          Id:id
        }
        let result = await videolivevideolivecategoryinfo(data)
        if(result.IsSuccess){
          this.materialImgs.business = e.BusinessLicense
          this.materialImgs.category = e.CategoryQualificationList
          this.materialImgs.shop = e.ProductQualificationList
          this.qualificationshow = true
        }
      }finally{
        this.tableloading = false
      }
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    query(){
      this.currentPage = 1
      this.gettablelist()
    },
    async gettablelist(){
      this.tableloading = true
      try{
        let data = {
          State:this.state,
          Skip:(this.currentPage-1)*this.sizepage,
          Take:this.sizepage
        }
        let result = await videolivesystemvideolivecategorypagelist(data)
        if(result.IsSuccess){
          // console.log(result.Result)
          this.tablelist = result.Result.Results
          this.total = result.Result.Total
        }
      }finally{
        this.tableloading = false
      }
      
    },
  },
}
</script>

<style lang = "less">
  .flexRow{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
  }
  .boldFont{
    font-weight: bold;
    color: #606266;
  }
  .fontHidden3{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:3;
    overflow: hidden;
  }
  .markBGC{
    position: relative;
    top:-160px;
    left:0px;
    z-index: 500;
    display: none;
  }
  .pointershow:hover .markBGC{
    display: block;
  }
  .diaLog-buttonBox{
    width:100%;
    margin-top:30px;
    text-align: right;
  }
  .touchImg:hover{
    .markBox{
      display: block;
    }
  }
  .touchImg{
    width:148px;
    height:148px;
  }
  .markBox2{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .markBox{
    width:148px;
    height:148px;
    background: rgba(0,0,0,.3);
    position: relative;
    top:-160px;
    display: none;
    z-index: 500;
  }
  .titleFont{
    font-size: 14px;
    color: #606266;
  }
</style>
<style scoped>
.operation ::v-deep .el-button{
    text-align: left;
  }
</style>